export const procesarData = (data, isAppend, isChecked, toClipboard) => {
  const toAppend = isAppend
  const sendToClipboard = toClipboard
  const getters = document.getElementById('getter').value
  const selection = document.getElementById('select').value
  const text = document.getElementById('text-holder')
  const getAnSetAPoner = getters === "1" ? ' {get;} \n' : getters === "2" ? ' {set;} \n' : ' {get; set;} \n'
  const privadoOPublico = selection === "1" ? 'public' : 'private'

  if(!toAppend) {
    text.value = ''
    let nombreClase = document.getElementById('class-name').value
    text.value += nombreClase.length > 0 ? `public class ${nombreClase} \n{\n` : `public class ClassWithOutName \n{\n`
    text.value += segregarColumnas(data, isChecked, getAnSetAPoner, privadoOPublico)
    if(!text.value.split('{')[1] || !text.value.split('{')[1].trim().length)
      throw new Error()
    text.value += '}'    
      
  } else {
    let actualTexto = text.value.split('}').slice(0, text.value.split('}').length -2).join().replaceAll(',', '}') + '}\n'
    let porFiltrar = actualTexto.split(';')
    let palabras = []
    porFiltrar = porFiltrar.forEach(linea => {
      if(linea.includes('public class'))
        palabras.push(linea.split('{')[1].trim().split(' ')[2])
      else if (linea.includes('private'))
        palabras.push(linea.substring(linea.indexOf('private') + 8).split(' ')[1].split('\t')[0])
      else if (linea.includes('public'))
        palabras.push(linea.substring(linea.indexOf('public') + 7).split(' ')[1].split('\t')[0])
    })
    ignoreMe(porFiltrar)
    
    let dataFiltrada = data
    dataFiltrada = dataFiltrada.replace(/[\r\n]/gm, '|')
    dataFiltrada = dataFiltrada.split('|').join()
    dataFiltrada = dataFiltrada.split(',,')
    dataFiltrada.forEach(dato => {
      let newDato = dato
      if(dato.includes('_'))
        dato = dato.replaceAll('_', '')
      let yaEsta = palabras.filter(x => x.toLowerCase() === dato.toLowerCase().split('\t')[0] || x.toLowerCase() === newDato.toLowerCase().split('\t')[0])
      if(yaEsta.length) {
        dataFiltrada = dataFiltrada.filter(x => x !== newDato)
      }
    })
    let nuevoTexto = segregarColumnas(dataFiltrada, isChecked, getAnSetAPoner, privadoOPublico, true).split('}').join().replaceAll(',', '}')
    text.value = actualTexto + nuevoTexto
    text.value += '}'
  }
  if(sendToClipboard) {
    navigator.clipboard.writeText(text.value);
  }
}

const ignoreMe = (data) => {

}

const segregarColumnas = (data, isChecked, getAnSetAPoner, privadoOPublico, excluir) => {
  let booleanoDesactivado = document.getElementById('append')
  if(booleanoDesactivado.disabled === true)
    booleanoDesactivado.attributes.removeNamedItem("disabled")
  let text = '';
  if(!excluir) {
    data = data.replace(/[\r\n]/gm, '|')
    data = data.split('|').join()
    data = data.split(',,')
  }
  for(let item of data){
    if(!item.includes('\t'))
      throw new Error()
    var newItem = ''
    var newItem2 = item.split('\t')[1].split(' ')[0]
    if(item.includes('_') && isChecked){
      let itemSplitted = item.split('\t')
      let formatteditem = itemSplitted[0].split('_')
      for(var a = 0;a < formatteditem.length;a++){
        newItem += formatteditem[a].substring(0, 1).toUpperCase() + formatteditem[a].substring(1).toLowerCase()
      }

    } else {
      newItem += item.split('\t')[0].substring(0, 1).toUpperCase() + item.split('\t')[0].substring(1).toLowerCase()
    }
    if(newItem2.includes('(')) {
      newItem2 = newItem2.split('(')[0]
    }
    switch(newItem2) {
      case 'tinyint':
        newItem = '   ' + privadoOPublico + ' byte ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'smallint':
        newItem = '   ' + privadoOPublico + ' short ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'int':
        newItem = '   ' + privadoOPublico + ' int ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'bigint':
          newItem = '   ' + privadoOPublico + ' Int64 ' + newItem + getAnSetAPoner
          text += newItem
          break;
      case 'bit':
        newItem = '   ' + privadoOPublico + ' bool ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'date':
        newItem = '   ' + privadoOPublico + ' DateTime ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'varbinary':
      case 'binary':
      case 'image':
        newItem = '   ' + privadoOPublico + ' byte[] ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'varchar':
      case 'text':
      case 'char':
      case 'nchar':        
      case 'nvarchar':
      case 'ntext':
        newItem = '   ' + privadoOPublico + ' String ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'time':
        newItem = '   ' + privadoOPublico + ' TimeSpan ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'datetime':
        newItem = '   ' + privadoOPublico + ' DateTime ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'float':
      case 'smallmoney':
      case 'numeric':
      case 'decimal':
        newItem = '   ' + privadoOPublico + ' Decimal ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'timestamp':
        newItem = '   ' + privadoOPublico + ' TimeSpan ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'uniqueidentifier':
          newItem = '   ' + privadoOPublico + ' Guid ' + newItem + getAnSetAPoner
          text += newItem
          break;
      case 'geography':
        newItem = '   ' + privadoOPublico + ' DbGeography ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'geometry':
        newItem = '   ' + privadoOPublico + ' DbGeometry ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'hierarchyid':
        newItem = '   ' + privadoOPublico + ' String ' + newItem + getAnSetAPoner
        text += newItem
        break;
      case 'datetimeoffset':
        newItem = '   ' + privadoOPublico + ' DateTimeOffset ' + newItem + getAnSetAPoner
        text += newItem
        break;
      default:
        break;        
    }
  }
  return text
}